/* eslint-disable @typescript-eslint/ban-types */
import QueryTags from '../types/QueryTags'
import { EndpointBuilder } from '@reduxjs/toolkit/dist/query/endpointDefinitions'
import { BaseQueryFn, FetchArgs, FetchBaseQueryError, FetchBaseQueryMeta } from '@reduxjs/toolkit/query/react'


const basePath = '/inventory'

const inventoryEndpoints = (builder: EndpointBuilder<
  BaseQueryFn<string | FetchArgs, unknown, FetchBaseQueryError, {}, FetchBaseQueryMeta>, QueryTags, 'data'>) =>
  ({
    fetchInventory: builder.query<any, string>({
      query: () => `${basePath}/getAllStock`,
      providesTags: ['inventory']
    }),
    addInventory: builder.mutation<any, any>({
      query: (body) => ({
        url: `${basePath}/addStock`,
        method: 'POST',
        body
      }),
      invalidatesTags: ['inventory']
    }),
    updateInventory: builder.mutation<any, any>({
      query: ({id, newQuantity, body}) => ({
        url: `${basePath}/updateStock/${id}`,
        method: 'PUT',
        body
      }),
      invalidatesTags: ['inventory']
    }),
    deleteInventory: builder.mutation<any, any>({
      query: (id) => ({
        url: `${basePath}/deleteStock/${id}`,
        method: 'DELETE',
      }),
      invalidatesTags: ['inventory']
    }),
  })

export default inventoryEndpoints
