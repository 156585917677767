/* eslint-disable @typescript-eslint/ban-types */
import QueryTags from '../types/QueryTags'
import { EndpointBuilder } from '@reduxjs/toolkit/dist/query/endpointDefinitions'
import { BaseQueryFn, FetchArgs, FetchBaseQueryError, FetchBaseQueryMeta } from '@reduxjs/toolkit/query/react'


const basePath = '/orders'

const ordersEndpoints = (builder: EndpointBuilder<
  BaseQueryFn<string | FetchArgs, unknown, FetchBaseQueryError, {}, FetchBaseQueryMeta>, QueryTags, 'data'>) =>
  ({
    fetchOrders: builder.query<any, any>({
      query: () => `${basePath}`
    }),
    fetchOrderById: builder.query<any, any>({
      query: (id) => `${basePath}/${id}`
    }),
    fetchOrderSummary: builder.query<any, any>({
      query:(id) => `${basePath}/${id}/summary`
    })
  })

export default ordersEndpoints
