const InventoryIcon = ({
  className,
  color,
}: {
  className?: string,
  color?: string,
}): JSX.Element => {
  return (
    <svg width="15" height="13" viewBox="0 0 15 13" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M13.6364 10.9091H15V12.2727H0V10.9091H1.36364V0.681818C1.36364 0.305264 1.6689 0 2.04545 0H8.86364C9.2402 0 9.54545 0.305264 9.54545 0.681818V10.9091H12.2727V5.45455H10.9091V4.09091H12.9545C13.3311 4.09091 13.6364 4.39617 13.6364 4.77273V10.9091ZM2.72727 1.36364V10.9091H8.18182V1.36364H2.72727ZM4.09091 5.45455H6.81818V6.81818H4.09091V5.45455ZM4.09091 2.72727H6.81818V4.09091H4.09091V2.72727Z" fill="#2A2F4E" />
    </svg>
  )
}

export default InventoryIcon
