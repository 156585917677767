/* eslint-disable @typescript-eslint/ban-types */
import QueryTags from '../types/QueryTags'
import { EndpointBuilder } from '@reduxjs/toolkit/dist/query/endpointDefinitions'
import { BaseQueryFn, FetchArgs, FetchBaseQueryError, FetchBaseQueryMeta } from '@reduxjs/toolkit/query/react'


const basePath = '/product-service/back/product'

const productsEndpoints = (builder: EndpointBuilder<
  BaseQueryFn<string | FetchArgs, unknown, FetchBaseQueryError, {}, FetchBaseQueryMeta>, QueryTags, 'data'>) =>
  ({
    fetchProducts: builder.query<any, any>({
      query: () => `${basePath}`,
      providesTags: ['products']
    }),
    deleteProduct: builder.mutation<any,any>({
      query: (id) => ({
        url: `${basePath}/${id}`,
        method: 'delete',
      }),
      invalidatesTags: ['products', 'vendorProduct']
    }),

    addProduct: builder.mutation<any,any>({
      query: (body) => ({
        url: `${basePath}`,
        method: 'post',
        body
      }),
      invalidatesTags: ['products']
    }),

    updateProduct: builder.mutation<any, any>({
      query: ({body, id}) => ({
        url: `${basePath}/${id}`,
        method: 'PUT',
        body
      }),
      invalidatesTags: ['products', 'vendorProduct']
    }),
    fetchProductColors: builder.query<any, any>({
      query: () => `/product-colors`,
    }),
    fetchProductSizes: builder.query<any, any>({
      query: () => `/product-sizes`,
    }),
  })

export default productsEndpoints
