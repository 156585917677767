import SideNav from "../components/sideNav"
import TopNav from "../components/topNav"
import AppContent from "./AppContent"
import { Navigate } from 'react-router-dom'
import { useEffect, useState } from 'react'


const DefaultLayout = () => {
  const [loading, setLoading] = useState(true)
  const [token, setToken] = useState(sessionStorage.getItem('accessToken'))

  useEffect(() => {
    setToken(sessionStorage.getItem('accessToken'));
    setLoading(false);
  }, []);

  if (loading) {
    return  <>loading....</>
  }

  if(!token){
    return <Navigate to='/login'/>
  }
  return(
    <div className="root-container">
      <div className="root-container__side-nav">
        <SideNav/>
      </div>
      <div className="root-container__body">
        <div className="top-nav">
          <TopNav/>
        </div>
        <div className="content">
          <AppContent/>
        </div>
      </div>
    </div>
  )
}
export default DefaultLayout