/* eslint-disable @typescript-eslint/ban-types */
import QueryTags from '../types/QueryTags'
import { EndpointBuilder } from '@reduxjs/toolkit/dist/query/endpointDefinitions'
import { BaseQueryFn, FetchArgs, FetchBaseQueryError, FetchBaseQueryMeta } from '@reduxjs/toolkit/query/react'


const basePath = '/product-service'

const brandsEndpoints = (builder: EndpointBuilder<
  BaseQueryFn<string | FetchArgs, unknown, FetchBaseQueryError, {}, FetchBaseQueryMeta>, QueryTags, 'data'>) =>
  ({
    fetchBrands: builder.query<any, string>({
      query: () => `${basePath}/brand`,
      providesTags: ['brands']
    }),

    createBrand: builder.mutation<any, any>({
      query: (body) => ({
        url: `${basePath}/brand`,
        method: 'post',
        body
      }),
      invalidatesTags: ['brands']
    }),

    deleteBrand: builder.mutation<any, any>({
      query: (id) => ({
        url: `${basePath}/brand/${id}`,
        method: 'delete',
      }),
      invalidatesTags: ['brands']
    }),

  })

export default brandsEndpoints
