/* eslint-disable @typescript-eslint/ban-types */
import QueryTags from '../types/QueryTags'
import { EndpointBuilder } from '@reduxjs/toolkit/dist/query/endpointDefinitions'
import { BaseQueryFn, FetchArgs, FetchBaseQueryError, FetchBaseQueryMeta } from '@reduxjs/toolkit/query/react'


const basePath = '/customer'

const customersEndpoints = (builder: EndpointBuilder<
  BaseQueryFn<string | FetchArgs, unknown, FetchBaseQueryError, {}, FetchBaseQueryMeta>, QueryTags, 'data'>) =>
  ({
    fetchCustomers: builder.query<any, string>({
      query: () => `${basePath}`
    }),
  })

export default customersEndpoints
