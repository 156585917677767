/* eslint-disable @typescript-eslint/ban-types */
import QueryTags from '../types/QueryTags'
import { EndpointBuilder } from '@reduxjs/toolkit/dist/query/endpointDefinitions'
import { BaseQueryFn, FetchArgs, FetchBaseQueryError, FetchBaseQueryMeta } from '@reduxjs/toolkit/query/react'


type Login = {
  username: string,
  password: string
}

const authBasePath = '/user-service/auth'

const authEndpoints = (builder: EndpointBuilder<
  BaseQueryFn<string | FetchArgs, unknown, FetchBaseQueryError, {}, FetchBaseQueryMeta>, QueryTags, 'data'>) =>
  ({
    login: builder.mutation<any, Login>({
      query: (body) => ({
        url: `${authBasePath}/login`,
        method: 'post',
        body,
      }),
    }),
  })

export default authEndpoints



