import side_nav_logo from '../../styles/images/Side-menu-header.svg'
import { useLocation, Link } from 'react-router-dom'
import { useRef, useState, useEffect } from 'react'
import './index.scss'
import KeyboardArrowDownOutlinedIcon from '@mui/icons-material/KeyboardArrowDownOutlined'
import frame_icon from '../../styles/images/Frame.svg'
import frame_white_icon from '../../styles/images/Frame-white.png'
import { OrdersIcon, CustomersIcon, InventoryIcon} from '../../components/icons'
import BarChartIcon from '@mui/icons-material/BarChart'
import PaymentsIcon from '@mui/icons-material/Payments'
import DeliveryDiningIcon from '@mui/icons-material/DeliveryDining'
import SettingsIcon from '@mui/icons-material/Settings';

const SideNav = () => {

  const menuItems = [
    {
      label: 'Reports',
      icon: <BarChartIcon style={{color: '#2A2F4E', width: '15px', height: '13px'}}/>,
      path: '/reports',
      activeIcon: frame_white_icon,
      requiredPermissions: ['REPORT_MENU', 'ALL']

    },
    {
      label: 'Vendors',
      icon: <InventoryIcon/>,
      path: '/vendors',
      activeIcon: frame_white_icon,
      requiredPermissions: ['VENDOR_MENU', 'ALL']
    },

    // {
    //   label: 'Products',
    //   icon: <InventoryIcon/>,
    //   path: '/products',
    //   activeIcon: frame_white_icon,
    //   requiredPermissions: ['PRODUCT_MENU']
    // },
    // {
    //   label: 'Inventory',
    //   icon: <InventoryIcon/>,
    //   path: '/inventory',
    //   activeIcon: frame_white_icon,
    //   requiredPermissions: ['INVENTORY_MENU', 'ALL']
    // },
    // {
    //   label: 'Users',
    //   icon: <CustomersIcon/>,
    //   path: '/users',
    //   activeIcon: frame_white_icon,
    //   requiredPermissions: ['USER_MENU', 'ALL']
    // },
    // {
    //   label: 'Orders',
    //   icon: <OrdersIcon />,
    //   path: '/orders',
    //   activeIcon: <OrdersIcon />,
    //   requiredPermissions: ['ORDER_MENU', 'ALL']

    // },
    // {
    //   label: 'Payments',
    //   icon: <PaymentsIcon style={{color: '#2A2F4E', width: '15px', height: '13px'}}/>,
    //   path: '/payments',
    //   activeIcon: frame_white_icon,
    //   requiredPermissions: ['PAYMENT_MENU', 'ALL']
    // },
    // {
    //   label: 'Deliveries',
    //   icon: <DeliveryDiningIcon style={{color: '#2A2F4E', width: '15px', height: '13px'}}/>,
    //   path: '/deliveries',
    //   activeIcon: frame_white_icon,
    //   requiredPermissions: ['DELIVERY_MENU', 'ALL']
    // },

    // {
    //   label: 'Staff',
    //   icon: <CustomersIcon />,
    //   path: '/staff',
    //   activeIcon: frame_white_icon,
    //   requiredPermissions: ['STAFF_MENU']
    // },

    // {
    //   label: 'Analytics',
    //   icon: frame_icon,
    //   path: '/analytics',
    //   activeIcon: frame_white_icon
    // },
    // {
    //   label: 'Discounts',
    //   icon: frame_icon,
    //   path: '/discounts',
    //   activeIcon: frame_white_icon
    // },
    // {
    //   label: 'Warehouse',
    //   icon: frame_icon,
    //   path: '/warehouse',
    //   activeIcon: frame_white_icon
    // },
    // {
    //   label: 'Authorization',
    //   children: [
    //     {
    //       label: 'Vendors',
    //       icon: <SettingsIcon style={{color: '#2A2F4E', width: '15px', height: '13px'}}/>,
    //       path: '/pending-vendors',
    //       activeIcon: frame_white_icon,
    //       requiredPermissions: ['vendors', 'ALL']
    //     },
    //     {
    //       label: 'Riders',
    //       icon: <SettingsIcon style={{color: '#2A2F4E', width: '15px', height: '13px'}}/>,
    //       path: '/pending-riders',
    //       activeIcon: frame_white_icon,
    //       requiredPermissions: ['riders', 'ALL']
    //     },
    //   ]
    // },
    {
      label: 'Settings',
      children: [
        {
          label: 'Brands',
          icon: <SettingsIcon style={{color: '#2A2F4E', width: '15px', height: '13px'}}/>,
          path: '/brands',
          activeIcon: frame_white_icon,
          requiredPermissions: ['ROLE_MENU', 'ALL']
        },
        // {
        //   label: 'Role',
        //   icon: <SettingsIcon style={{color: '#2A2F4E', width: '15px', height: '13px'}}/>,
        //   path: '/roles',
        //   activeIcon: frame_white_icon,
        //   requiredPermissions: ['ROLE_MENU', 'ALL']
        // },
        // {
        //   label: 'Permissions',
        //   icon: <SettingsIcon style={{color: '#2A2F4E', width: '15px', height: '13px'}}/>,
        //   path: '/permissions',
        //   activeIcon: frame_white_icon,
        //   requiredPermissions: ['PERMISSION_MENU', 'ALL']
        // },
        {
          label: 'Category',
          icon: <SettingsIcon style={{color: '#2A2F4E', width: '15px', height: '13px'}}/>,
          path: '/categories',
          activeIcon: frame_white_icon,
          requiredPermissions: ['CATEGORY_MENU', 'ALL']
        },
        {
          label: 'Sub category',
          icon: <SettingsIcon style={{color: '#2A2F4E', width: '15px', height: '13px'}}/>,
          path: '/sub-category',
          activeIcon: frame_white_icon,
          requiredPermissions: ['SUB_CATEGORY_MENU', 'ALL']
        },
      ]
    },
  ]


  const token = sessionStorage.getItem('accessToken')
  
  // const decodeToken = token && JSON.parse(atob(token?.split('.')[1]))
  // const permissionArr = decodeToken?.permissions


  // const getMenuItem = () => {
  //   const filteredMenu = menuItems.filter(item => {
  //     if (item.requiredPermissions) {
  //       return item.requiredPermissions.some(permission => permissionArr?.includes(permission));
  //     } else if (item.children) {
  //       return item.children.some(child =>
  //         child.requiredPermissions?.some(permission => permissionArr?.includes(permission))
  //       );
  //     }
  //     return true;
  //   });

  //   return filteredMenu;
  // }

  const location = useLocation()
  const pathname = location.pathname
  const contentEl = useRef() as React.MutableRefObject<HTMLInputElement>
  const [clicked, setClicked] = useState(-1)
  // const [menu, setMenuItems] = useState(getMenuItem())


  // useEffect(() => {
  //   if (token) {
  //     setMenuItems(getMenuItem())
  //   }
  // }, [token])

  const handleToggle = (id: number) => {
    if (clicked === id) {
      return setClicked(-1)
    } else{
      setClicked(id)
    }
  }

  return (
    <div>
      <div className='side-nav-logo'>
        {/* <img src={side_nav_logo} alt="urban-discount-logo" /> */}
        {/* <h1>Virtual shop</h1> */}
      </div>
      {
        // @ts-ignore
        menuItems.map((menuItem, id) => {
          if (!menuItem.children) {
            return (
              <Link to={menuItem.path} className={`menu-item-container ${(pathname.indexOf(menuItem.path) > -1) ? 'active-menu' : ''}`}>
                <div className='icon-container'>
                  { menuItem.icon }
                </div>
                <span>{menuItem.label}</span>
              </Link>
            )
          } else {
            return (
              <div className=' drop-down-menu-item-container'>
                <div className='drop-down-menu-item-container__header' onClick={() => handleToggle(id)}>
                  <span>{menuItem.label}</span>
                  <KeyboardArrowDownOutlinedIcon />
                </div>
                <div
                  ref={contentEl}
                  style={
                    clicked === id
                      ? { height: contentEl?.current?.scrollHeight }
                      : { height: '0px' }
                  }
                  className='drop-down-menu-item-container__wrapper'>
                  <div className='drop-down-menu-item-container__content'>
                    {
                      // @ts-ignore
                      menuItem.children.map(el => {
                        return (
                          <Link to={el.path} className={`menu-item-container ${(pathname.indexOf(el.path) > -1) ? 'active-menu' : ''}`}>
                            <div className='icon-container'>
                              {el.icon}
                            </div>
                            <span>{el.label}</span>
                          </Link>
                        )
                      })}
                  </div>
                </div>
              </div>
            )
          }
        })}
    </div>
  )
}

export default SideNav