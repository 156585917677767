import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react'
import authEndpoints from './auth'
import vendorsEndpoints from './vendors'
import customersEndpoints from './customers'
import categoriesEndpoints from './categories'
import rolesEndpoints from './roles'
import permissionsEndpoints from './permissions'
import subCategoriesEndpoints from './subCategories'
import inventoryEndpoints from './inventory'
import productsEndpoints from './products'
import ordersEndpoints from './orders'
import paymentsEndpoints from './payments'
import deliveriesEndpoints from './deliveries'
import brandsEndpoints from './brand'

const DcoreUrl = process.env.REACT_APP_D_CORE_URL

const baseQuery = fetchBaseQuery({
  baseUrl: DcoreUrl,
  prepareHeaders: (headers, { endpoint }) => {

    const token = sessionStorage.getItem('accessToken')
    if (token) {
      headers.set('Authorization', `Bearer ${token}`)
    }
    headers.set('Content-Type', 'application/json')
    headers.set('accept', 'application/json')
    headers.set('lang', 'ENGLISH')
    headers.set('CHANNEL', 'BACK')
    return headers
  },
})

const baseQueryIntercep: typeof baseQuery = async (args, api, extraOptions) => {
  let queryResult = await baseQuery(args, api, extraOptions)
  return queryResult
}

const appApi = createApi({
  reducerPath: 'data',
  baseQuery: baseQueryIntercep,
  tagTypes: [
    'subscribe',
    'vendors',
    'roles',
    'subCategory',
    'category',
    'inventory',
    'products',
    'vendorsStore',
    'vendorProduct',
    'PendingDriverApproval',
    'brands'
  ],
  endpoints: (builder) => ({
    ...authEndpoints(builder),
    ...vendorsEndpoints(builder),
    ...customersEndpoints(builder),
    ...categoriesEndpoints(builder),
    ...rolesEndpoints(builder),
    ...permissionsEndpoints(builder),
    ...subCategoriesEndpoints(builder),
    ...inventoryEndpoints(builder),
    ...productsEndpoints(builder),
    ...ordersEndpoints(builder),
    ...paymentsEndpoints(builder),
    ...deliveriesEndpoints(builder),
    ...brandsEndpoints(builder)
  }),
})

export default appApi

export const {
  useLoginMutation,

  //vendors 
  useFetchVendorsQuery,
  useAddVendorMutation,
  useAddVendorWalletMutation,
  useFetchStoresListQuery,
  useCreateVendoreStoreMutation,
  useFetchVendorStoresQuery,
  useCreateVendorStaffMutation,
  useDeleteVendorStaffMutation,
  useUpdateVendorStaffMutation,
  useFetchVendorProductsQuery,
  useAddVendorProductMutation,
  useFetchVendorStaffQuery,
  useFetchProductColorsQuery,
  useFetchProductSizesQuery,


  //customers
  useFetchCustomersQuery,

  //categories
  useFetchCategoriesQuery,
  useAddCategoryMutation,
  useLazyFetchSubCategoriesByIdQuery,
  useUpdateCategoryMutation,
  useDeleteCategoryMutation,
  useEditCategoryImageMutation,

  //roles management
  useFetchRolesQuery,
  useCreateARoleMutation,
  useDeleteARoleMutation,
  useUpdateRoleMutation,

  //brands management
  useFetchBrandsQuery,
  useCreateBrandMutation,
  useDeleteBrandMutation,

  //permissions
  useFetchPermissionsQuery,

  //sub categories
  useLazyFetchSubCategoriesQuery,
  useAddSubCategoryMutation,
  useUpdateSubcategoryMutation,
  useDeleteSubCategoryMutation,
  useEditSubcategoryImageMutation,


  // inventory management
  useFetchInventoryQuery,
  useAddInventoryMutation,
  useUpdateInventoryMutation,
  useDeleteInventoryMutation,

  //products management
  useFetchProductsQuery,
  useDeleteProductMutation,
  useAddProductMutation,
  useUpdateProductMutation,

  //orders management
  useFetchOrdersQuery,
  useFetchOrderByIdQuery,
  useFetchOrderSummaryQuery,

  // payments management
  useFetchPaymentsQuery,

  //deliveries management
  useFetchDeliveriesQuery,
  useFetchPendingDridersQuery,
  useApproveARiderMutation,

} = appApi
