/* eslint-disable @typescript-eslint/ban-types */
import QueryTags from '../types/QueryTags'
import { EndpointBuilder } from '@reduxjs/toolkit/dist/query/endpointDefinitions'
import { BaseQueryFn, FetchArgs, FetchBaseQueryError, FetchBaseQueryMeta } from '@reduxjs/toolkit/query/react'


const basePath = '/deliveries'

const deliveriesEndpoints = (builder: EndpointBuilder<
  BaseQueryFn<string | FetchArgs, unknown, FetchBaseQueryError, {}, FetchBaseQueryMeta>, QueryTags, 'data'>) =>
  ({
    fetchDeliveries: builder.query<any, any>({
      query: () => `${basePath}`
    }),
    fetchPendingDriders: builder.query<any,any>({
      query: () => `/driver/pending-driver-approval`,
      providesTags: ['PendingDriverApproval']
    }),
    approveARider: builder.mutation<any, any>({
      query: (id) => ({
        url: `/driver/approval/${id}`,
        method: 'PATCH',
      }),
      invalidatesTags: ['PendingDriverApproval']
    })
  })

export default deliveriesEndpoints
